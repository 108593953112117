import './App.css';

import { useMemo } from 'react';

function App() {
  const iframeUrl = useMemo(() => {
    return new URLSearchParams(window.location.search).get('iframeUrl');
  }, []);

  console.log('window.location.search:', window.location.search);
  console.log('iframeUrl:', iframeUrl);

  return (
    <div className="App">
      {
        iframeUrl ? (
          <iframe src={iframeUrl} title="iframe" style={{ width: '100%', height: '100vh', border: 'none' }} />
        ) : (
          <form action="/">
            <input type="text" name="iframeUrl" placeholder="Enter URL" />
            <button type="submit">Submit</button>
          </form>
        )
      }
    </div>
  );
}

export default App;
